import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Verified from './pages/verified';


import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer';

import BlogPage from './pages/blogs/all-blogs';
import Blog from './pages/blogs/Blog';

import ProtectedRoute from './components/ProtectedRoute';


import DashboardLayout from './components/DashboardLayout';
import Userfactor from './pages/dashboard/Userfactor';
import Subscriptions from './pages/dashboard/Subscriptions';
import RedirectHandler from './pages/dashboard/RedirectHandler';
import UserGuide from './pages/dashboard/Userguide';
import Settings from './pages/dashboard/Settings';


import Catalog from './pages/dashboard/Cataloge';
import Collection from './pages/Collection';
import ActivateAccount from './pages/activateaccount';

import { GoogleOAuthProvider } from '@react-oauth/google';

import './assets/css/index.css';
import './assets/css/dashboard.css';
import axios from 'axios';
import QuickBooksCallback from './pages/dashboard/Quickbook_callback';




function App() {
  axios.defaults.xsrfCookieName = 'csrftoken'; // The name of the CSRF cookie set by Django
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  return (
    <GoogleOAuthProvider clientId="468159969650-l611qsdie918006didhdfrffkmb2qa3r.apps.googleusercontent.com">
    <BrowserRouter>
      <Routes>
        {/* Main routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/activate/:uid/:token" element={<><ActivateAccount /><Footer /></>} />
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/verified" element={<Verified />} />
                
        <Route path="/" element={<><Navbar /><Home /><Footer /></>} />
        <Route path="/all-blogs" element={<><Navbar /><BlogPage /><Footer /></>} />
        <Route path="/blog/:id" element={<><Navbar /><Blog /><Footer /></>} />
        <Route path="/collections/:categoryId" element={<Collection />} />

      
      
        {/* Dashboard routes */}
        <Route element={<ProtectedRoute/>}>
          <Route path="/dashboard" element={<DashboardLayout />}>
          {/* Default route when navigating to /dashboard */}
            <Route index element={<Catalog />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="userfactor" element={<Userfactor />} />
            <Route path="settings" element={<Settings />} />
            <Route path="user-guide" element={<UserGuide />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="subscriptions/success" element={<RedirectHandler />} />
            <Route path="subscriptions/cancel" element={<RedirectHandler />} />
            <Route path="quickbooks/callback" element={<QuickBooksCallback />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;



//   return (
//     <Router>
//     <Navbar />
//     <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/register" element={<Register />} />
//         <Route path="/forgot-password" element={<ForgotPassword />} />
//       <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />


//         <Route element={<ProtectedRoute/>}>
//         <Route path="/userfactor" element={<Userfactor />} />
//         <Route path="/catalog" element={<Catalog />}/>
//         <Route path="/collections/:categoryId" element={<Collection />} />
//         </Route>
//       </Routes>
// </Router>
//   );
// }
