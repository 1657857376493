const config = {
  // apiBaseUrl: 'http://127.0.0.1:8000/api',
  // imageBaseUrl: 'http://127.0.0.1:8000',
  // apiBaseUrl: 'http://34.206.146.16:8000',
  // apiBaseUrl: 'http://34.206.42.215',
  apiBaseUrl: 'https://accupricelists.com/api',
  imageBaseUrl: 'https://accupricelists.com',

  // frontend URL
  // frontend_url:"http://localhost:3000",
  frontend_url:"https://accupricelists.com",

  // variables
  // quickbook_clientid :'ABVsaw4X0vspP3qygzBncEABQazUCfV8BoyDYsjNwStY05fyJu',
  quickbook_clientid :'ABXFSAV7BJeDrZswZeb5dQTwxACrFQSiBNidHp7D2EvrtXadqR',

  
  
  };
  
  export default config;
