import React,{ useState }  from 'react';


import axios from 'axios';
import config from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BasicModal from "./BasicModal";
import Button from "@mui/material/Button";


const Footer: React.FC = () => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  
  const handlenewsSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${config.apiBaseUrl}/newsletter_email/`, { email });
      if (response.data.success) {
        toast.success('News letter status updated.');
      } else {
        setMessage(response.data.message || 'Something went wrong.');
      }
    } catch (error: any) {
      // Check if the error response exists from the backend
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || 'An unknown error occurred.';
        toast.error(`${errorMessage}`);
      } else {
        toast.error('An error occurred while sending the request.');
      }
    }
  };
  return (
    <>
    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover />
    <footer className="footer-section">
      <div className="footer-top">
        <div className="footer-logo-social">
          <div className="footer-logo">
            <img src="/images/logo.png" alt="AccuPrice Logo" />
          </div>
          <div className="footer-social">
            <a href="https://facebook.com/" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
            <a href="https://linkedin.com/" aria-label="LinkedIn"><i className="fab fa-linkedin"></i></a>
            <a href="https://twitter.com/" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
            <a href="https://instagram.com/" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
        <div className="footer-newsletter">
          <h2>Join our newsletter</h2>
          <p className="stay-uptodate">Stay up-to-date with our new blog posts by subscribing here</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Enter your email" required  value={email} onChange={(e) => setEmail(e.target.value)}/>
            <button onClick={handlenewsSubmit} type="submit">Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
        <Button onClick={() => setShowPrivacyModal(true)}>Privacy Policy</Button>
        <Button onClick={() => setShowTermsModal(true)}>Terms of Service</Button>
        <Button onClick={() => setShowCookiesModal(true)}>Cookies Settings</Button>
        </div>
        <p className="footer-copyright">
          © 2024 All rights reserved.
        </p>
      </div>
      {/* Privacy Policy Modal */}
      <BasicModal title={"Privacy Policy"} isOpen={showPrivacyModal} onClose={() => setShowPrivacyModal(false)}>
        <div className="footer-modal">
          <p>Last Updated: <strong>9/15/2024</strong></p>
          <p>At AccuPriceLists.com, we are committed to protecting your privacy and ensuring the security of your
            personal information. This Privacy Policy outlines how we collect, use, and store your data, and the rights
            you have regarding your information.</p>
          <ol>
            <li><strong>Information We Collect</strong>
              <p>We collect the following types of data when you use our website:</p>
              <ul>
                <li>Contact information (e.g., name, email address)</li>
                <li>User preferences (e.g., desired markups, sales tax preferences)</li>
                <li>Marketing data (e.g., opt-ins for newsletters or promotional materials)</li>
              </ul>
              <p>We do not collect any additional browsing data, cookies, or payment information directly. All payments
                are processed through Stripe, our third-party payment processor.</p>
            </li>
            <li><strong>How We Use Your Information</strong>
              <p>We use the information we collect for the following purposes:</p>
              <ul>
                <li>To process and manage your subscriptions and purchases</li>
                <li>To send you important updates or notifications regarding your account</li>
                <li>For marketing purposes, including newsletters and promotional offers, if you have opted in</li>
              </ul>
              <p>Your information will not be shared with any third parties beyond what is necessary for payment
                processing via Stripe.</p>
            </li>
            <li><strong>Data Retention</strong>
              <p>We retain your personal data indefinitely to allow for future interactions and subscriptions. You may,
                at any time, request deletion of your data or manage your subscription preferences.</p>
            </li>
            <li><strong>User Rights</strong>
              <p>You have the following rights regarding your personal data:</p>
              <ul>
                <li><strong>Access:</strong> You may request a copy of the data we hold about you.</li>
                <li><strong>Deletion:</strong> You may request that we delete your personal information from our
                  records.
                </li>
                <li><strong>Opt-out:</strong> You may opt out of receiving marketing communications at any time by
                  adjusting your preferences in your account portal or by contacting us directly.
                </li>
              </ul>
              <p>To exercise any of these rights, please contact us at <a
                  href="mailto:support@accupricelists.com">support@accupricelists.com</a>.</p>
            </li>
            <li><strong>Data Security</strong>
              <p>We take data security seriously and use encryption and secure servers to protect your personal
                information. While we strive to ensure the security of your data, no method of transmission over the
                Internet is 100% secure, and we cannot guarantee absolute security.</p>
            </li>
            <li><strong>Changes to the Privacy Policy</strong>
              <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and you
                will be notified of significant updates via email or account notification.</p>
            </li>
            <li><strong>Contact Us</strong>
              <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at <a
                  href="mailto:support@accupricelists.com">support@accupricelists.com</a>.</p>
            </li>
          </ol>
        </div>
      </BasicModal>

      {/* Terms of Service Modal */}
      <BasicModal title={"Terms and Conditions"} isOpen={showTermsModal} onClose={() => setShowTermsModal(false)}>
        <div className="footer-modal">
          <p>Last Updated: <strong>9/15/2024</strong></p>
          <p>By accessing and using this website, you agree to comply with the following
            Terms and Conditions. Please read them carefully before purchasing or using any services.</p>
          <ol>
            <li><strong>Use of Price Lists</strong>
              <p>The materials price lists provided on this website are for your company's internal use only. You may
                not
                redistribute, resell, or share the price lists, data, or any other information obtained from this
                website.
                Failure to comply with this condition may result in the termination of your access without a refund.</p>
            </li>
            <li><strong>Purchases and Refund Policy</strong>
              <p>All purchases made on AccuPriceLists.com are final. We do not offer refunds once a plan has been
                purchased. You will have access to your downloads until your plan expires or you reach the maximum
                number
                of downloads allowed under your plan. However, if your subscription auto-renews and you have not
                downloaded any files during the new subscription period, you have 10 days to request a refund. After
                this
                10-day period, refunds will no longer be available.</p>
            </li>
            <li><strong>Subscription and Auto-Renewal</strong>
              <p>Users may opt into auto-renewal for their subscription. AccuPriceLists.com reserves the right not to
                notify users of auto-renewals. Users can manage their subscription settings and auto-renewal preferences
                via their account portal.</p>
            </li>
            <li><strong>Payment Processing</strong>
              <p>All payments are processed through Stripe, our third-party payment processor. We accept any payment
                methods available through Stripe’s API. By using Stripe, you agree to abide by their terms and
                conditions
                as well.</p>
            </li>
            <li><strong>Intellectual Property</strong>
              <p>All content, including but not limited to price lists, design, branding, and materials on this site, is
                the intellectual property of AccuPriceLists.com. You may not reproduce, distribute, or use any content
                from this site without express permission from us.</p>
            </li>
            <li><strong>Accuracy of Information</strong>
              <p>We strive to provide the most accurate, up-to-date pricing available, but we do not guarantee that all
                prices will be completely accurate at all times. Prices are subject to changes from third-party sources
                and human error. AccuPriceLists.com holds no liability for any discrepancies or inaccuracies in the
                price
                lists.</p>
            </li>
            <li><strong>User Feedback and Reviews</strong>
              <p>By providing feedback through email or public reviews, you grant AccuPriceLists.com the right to use
                your
                comments for promotional purposes or to improve our services. We reserve the right to use such feedback
                without compensation or further approval from you.</p>
            </li>
            <li><strong>Third-Party Services</strong>
              <p>We may use third-party services to facilitate the operation of our website, including payment
                processing,
                hosting, and analytics. Your interactions with these third-party services are governed by their terms
                and
                conditions.</p>
            </li>
            <li><strong>Termination of Access</strong>
              <p>We reserve the right to terminate your access to the site without refund if we believe that you have
                violated these Terms and Conditions, specifically by redistributing or reselling any of our content.</p>
            </li>
            <li><strong>Governing Law</strong>
              <p>These Terms and Conditions are governed by and construed in accordance with the laws of the state of
                California. Any disputes arising from your use of this website will be handled under California law.</p>
            </li>
            <li><strong>Indemnity Clause</strong>
              <p>By using this website and its services, you agree to indemnify, defend, and hold harmless AccuPrice Lists, its affiliates, employees, agents, and licensors from any claims, damages, liabilities, or expenses (including legal fees) arising from your use of our website, customized price lists, or integration with third-party software. This includes, but is not limited to, inaccuracies in the price lists, improper use, or any actions resulting from customization and integration into your business processes. AccuPrice Lists assumes no liability for any losses incurred through the use of these services.</p>
            </li>
          </ol>
        </div>
      </BasicModal>

      {/* Cookies Settings Modal */}
      <BasicModal title={"Cookies Notification"} isOpen={showCookiesModal} onClose={() => setShowCookiesModal(false)}>
        <div className="footer-modal">
          <p>Last Updated: <strong>9/15/2024</strong></p>
          <p>At this time, AccuPriceLists.com does not use cookies or collect browsing data. If this changes in the future, we will update our policies accordingly.
          </p>
        </div>
      </BasicModal>
    </footer>
    </>);
};

export default Footer;
